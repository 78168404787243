<template>
  <TestarTodos />
</template>

<script>
import TestarTodos from "@/components/invariantes/TestarTodos.vue"; // @ is an alias to /src

export default {
  components: {
    TestarTodos
  }
};
</script>
